import chose1 from "../images/chos1.svg"
import chose2 from "../images/chos2.svg"
import chose3 from "../images/chos3.svg"
import chose4 from "../images/chos4.svg"

export const ChooseData =[
    {
        id :1,
        img :chose1,
        name : "Save your money",
        para :"There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration."
    },
    {
        id :2,
        img :chose2,
        name : "Easy process system",
        para :"There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration."
    },
    {
        id :3,
        img :chose3,
        name : "Fast and reliable",
        para :"There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration."
    },
    {
        id :4,
        img :chose4,
        name : "24 X 7 Support",
        para :"There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration."
    },
]