import React, { useEffect, useState } from "react";
import { BlogData } from "../Data/BlogData";
import { Link, createSearchParams } from "react-router-dom";
import calender from "../images/calender.svg";
import { HiOutlineUser } from "react-icons/hi";
import { FiArrowUpRight } from "react-icons/fi";

const Blogs = ({ showHeading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showHeading) {
      setData(BlogData.slice(0, 3));
    }else{
      setData(BlogData);
    }
  }, []);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {showHeading && (
          <div className="flex flex-col items-center mb-10">
            <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Recent News & Blogs
            </p>
            <h2 className="max-w-[500px] mx-auto text-center">
              Featured tips and tricks About Insurance
            </h2>
          </div>
        )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-12 z-[1] relative mb-10">
            {data.map((e, i) => (
              <Link
                to={`/our-blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group rounded bg-troo-white relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto"
              >
                <div>
                  <img src={e.img} alt={e.img} />
                </div>
                <div className="p-5 ">
                  <div className="flex gap-5 mb-4">
                    <p className="flex items-center gap-2">
                      <img src={calender} alt="calender" />
                      {e.date}
                    </p>
                    <div className="border-l border-troo-black1"></div>
                    <p className="flex items-center gap-2">
                      <HiOutlineUser /> {e.pname}
                    </p>
                  </div>
                  <h4>{e.name}</h4>
                </div>
                <div className="rounded-b-md flex justify-between p-5 border-t-2 border-troo-black1 group-hover:bg-troo-primary transition-all ease-in-out duration-500">
                  <Link className="font-redHate font-bold">Read Full Blog</Link>
                  <FiArrowUpRight className="p-1 w-7 h-7 bg-troo-black1 flex justify-center items-center rounded-full text-troo-white group-hover:bg-troo-secondary transition-all ease-in-out duration-500" />
                </div>
              </Link>
            ))}
          </div>
        {showHeading && (
          <div className="text-center">
            <Link to="/our-blogs" className="btn1 border-troo-secondary hover:bg-troo-secondary hover:text-troo-white">
              Read All Blogs
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
