import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Brands from "../../Components/Brands";
import CaseStudy from "../../Components/CaseStudy";
import useDocumentTitle from "../../Hooks/PageTitle";

const CaseStudyPage = () => {
  useDocumentTitle("TRoo Insurance | CaseStudy Page")
  return (
    <Layout>
      <BannerGlobal />
      <CaseStudy/>
      <Brands/>
    </Layout>
  );
};

export default CaseStudyPage;
