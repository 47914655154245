import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ServiceDetails from '../../Components/ServiceDetails'
import Brands from '../../Components/Brands'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Insurance | Service Details Page")
  return (
    <Layout>
    <BannerGlobal />
    <ServiceDetails/>
    <Brands/>
  </Layout>
  )
}

export default ServiceDetailsPage