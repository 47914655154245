import React from "react";
import { Link } from "react-router-dom";
import { FiUser, FiMail, FiPhoneCall, FiCreditCard } from "react-icons/fi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";
import { BiTime } from "react-icons/bi";

const Contacts = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-troo-whitefade p-8">
            <h2 className="pb-5 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:w-16 before:h-[3px] before:bg-troo-secondary">
              Need Any Help? Or Looking For an Agent
            </h2>
            <form className="mt-6 w-full">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full relative">
                    <p className="mb-3">Full Name</p>
                    <FiUser className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-4 pl-10 bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Your Email</p>
                    <FiMail className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="email"
                      placeholder="Your Email Address"
                      className="p-4 pl-10 bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Enter Your Phone</p>
                    <FiPhoneCall className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="number"
                      placeholder="Enter Your Phone"
                      className="p-4 pl-10 bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Select Subject</p>
                    <FiCreditCard className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <select className="p-4 pl-10 bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary">
                      <option disabled selected>
                        Insurance Type
                      </option>
                      <option>Home Insurance</option>
                      <option>Health Insurance</option>
                      <option>Travel Insurance</option>
                    </select>
                  </div>
                </div>
                <div className="w-full">
                  <p className="mb-3">Write Comment</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-5 bg-transparent border focus:outline-none focus-visible:ring-1 rounded w-full"
                    rows={5}
                  />
                </div>
                <div>
                  <Link className="btn1 bg-troo-primary">Post Comment</Link>
                </div>
              </div>
            </form>
          </div>
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div className="flex gap-5 p-5 bg-troo-whitefade rounded group shadow">
                <div>
                  <div className="text-troo-primary bg-troo-green2 w-12 h-12 p-2 flex justify-center items-center rounded-full group-hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    <FiPhoneCall className="text-xl" />
                  </div>
                </div>
                <div>
                  <h4>Call Us on:</h4>
                  <p>+44 123 456 7890</p>
                  <p>+44 123 456 7890</p>
                </div>
              </div>
              <div className="flex gap-5 p-5 bg-troo-whitefade rounded group shadow">
                <div>
                  <div className="text-troo-primary bg-troo-green2 w-12 h-12 p-2 flex justify-center items-center rounded-full group-hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    <HiOutlineMailOpen className="text-xl" />
                  </div>
                </div>
                <div>
                  <h4>Email Us on:</h4>
                  <p>info@troothemes.com</p>
                  <p>Contact@troothemes.com</p>
                </div>
              </div>
              <div className="flex gap-5 p-5 bg-troo-whitefade rounded group shadow">
                <div>
                  <div className="text-troo-primary bg-troo-green2 w-12 h-12 p-2 flex justify-center items-center rounded-full group-hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    <CiLocationOn className="text-xl" />
                  </div>
                </div>
                <div>
                  <h4>Visit Us:</h4>
                  <p>Ewingar Road Lionsville,</p>
                  <p>New South Wales, Aus</p>
                </div>
              </div>
              <div className="flex gap-5 p-5 bg-troo-whitefade rounded group shadow">
                <div>
                  <div className="text-troo-primary bg-troo-green2 w-12 h-12 p-2 flex justify-center items-center rounded-full group-hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    <BiTime className="text-xl" />
                  </div>
                </div>
                <div>
                  <h4>Office Time</h4>
                  <p>Mon to fri: 10AM to 06PM</p>
                  <p>Sat-Sun Closed</p>
                </div>
              </div>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                title="gmap"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
