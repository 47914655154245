

export const FaqData =[
    {
        id:1,
        name : "What does a health insurancecover?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:2,
        name : "Why do I need to buy life insurance policy?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:3,
        name : "What are different typesof life insurance policies?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:4,
        name : "What are different types of Motor Insurance Policy?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:5,
        name : "Whether the period of travel insurance can be extended?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:6,
        name : "If pre-approval is required, then what is the procedure?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:7,
        name : "What are different types of Motor Insurance Policy?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:8,
        name : "Whether the period of travel insurance can be extended?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:9,
        name : "If pre-approval is required, then what is the procedure?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:10,
        name : "What are different types of Motor Insurance Policy?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:11,
        name : "Whether the period of travel insurance can be extended?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
    {
        id:12,
        name : "If pre-approval is required, then what is the procedure?",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations."
    },
]