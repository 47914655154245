import React, { useState } from "react";
import vicon1 from "../images/videoIcon1.svg";
import vicon2 from "../images/videoIcon2.svg";
import vicon3 from "../images/videoIcon3.svg";
import vicon4 from "../images/videoIcon4.svg";
import videoicon from "../images/VideoIcon.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Video = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="bg-video-bg bg-no-repeat w-full bg-troo-secondary pt-12 lg:pt-24 pb-24 lg:pb-24">
      <div className="main_container1">
        <div className="flex flex-col items-center gap-5 mb-8 text-troo-white">
          <p className=" text-troo-white relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
            Our Achievements
          </p>
          <h2 className="max-w-[500px] mx-auto text-center">
            We Are Professionals And More Experiences
          </h2>
        </div>
        <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 relative z-[1]">
          <div className="flex gap-5 p-5 text-troo-white bg-troo-secondary rounded group relative hover:before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto">
            <div>
              <img src={vicon1} alt={vicon1} />
            </div>
            <div>
              <div className="font-redHate text-5xl font-bold mb-2">
                <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={10} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger>
              </div>
              <h5>Register Member</h5>
            </div>
          </div>
          <div className="flex gap-5 p-5 text-troo-white bg-troo-secondary rounded group relative hover:before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto">
            <div>
              <img src={vicon2} alt={vicon2} />
            </div>
            <div>
              <div className="font-redHate text-5xl font-bold mb-2">
                <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={25} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger>
              </div>
              <h5>Award Wining</h5>
            </div>
          </div>
          <div className="flex gap-5 p-5 text-troo-white bg-troo-secondary rounded group relative hover:before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto">
            <div>
              <img src={vicon3} alt={vicon3} />
            </div>
            <div>
              <div className="font-redHate text-5xl font-bold mb-2">
                <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={89} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger>
              </div>
              <h5>Skilled Expert</h5>
            </div>
          </div>
          <div className="flex gap-5 p-5 text-troo-white bg-troo-secondary rounded group relative hover:before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto">
            <div>
              <img src={vicon4} alt={vicon4} />
            </div>
            <div>
              <div className="font-redHate text-5xl font-bold mb-2">
              <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={11} end={89} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger></div>
              <h5>Projects completed</h5>
            </div>
          </div>
        </div>
        <div className="py-44 mb-[-300px] bg-video-img bg-no-repeat bg-cover bg-right w-full relative before:content-[''] before:absolute before:w-full before:h-full before:top-0 before:left-0 before:bg-troo-secondary before:opacity-50 before:z-[1]">
          <div
            className="flex justify-center items-center relative z-10"
            onClick={() => window.my_modal_2.showModal()}
          >
            <img src={videoicon} alt="videoIcon" />
          </div>
          <dialog id="my_modal_2" className="modal">
            <form
              method="dialog"
              className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
            >
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/DUfXdcpEfMs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </form>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default Video;
