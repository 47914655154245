import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Brands from '../../Components/Brands'
import PricingCard from '../../Components/PricingCard'
import useDocumentTitle from '../../Hooks/PageTitle'


const PricingPlanPage = () => {
  useDocumentTitle("TRoo Insurance | Pricing Plan Page")
  return (
    <Layout>
      <BannerGlobal />
      <PricingCard showHeading={false}/>
      <Brands/>
    </Layout>
  )
}

export default PricingPlanPage