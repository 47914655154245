import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DetailsImg from "../images/blogDetailsImg.png";
import { BsArrowRight, BsCheckLg } from "react-icons/bs";
import detailsSideImg from "../images/blogDtlsSide.png";
import { Link } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { GoArrowUpRight } from "react-icons/go";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { ServiceData } from "../Data/ServiceData";
import { BlogDetailsData } from "../Data/BlogDetailsData";

const BlogDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");

  useEffect(() => {
    const filData = BlogData.find((data) => data?.id == id);
    setHeading(filData?.name);
    setPicture(filData?.img);
  }, [id]);
  return (
    <div className=" w-full py-12 lg:py-24 bg-troo-white ">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-8">
          <div className="w-full lg:w-[68%] flex flex-col items-start gap-5">
            <h2>
              {heading || "3 life insurance under writing predictions for 2023"}
            </h2>
            <div className="flex gap-10 mb-10">
              <p className="relative pb-3 before:content-[''] before:absolute before:h-[2px] before:w-24 before:bg-troo-black1 before:bottom-0 before:left-0">
                Posted By: Adina Aliver
              </p>
              <p className="relative pb-3 before:content-[''] before:absolute before:h-[2px] before:w-24 before:bg-troo-black1 before:bottom-0 before:left-0">
                Date: 23 March, 2023
              </p>
              <p className="relative pb-3 before:content-[''] before:absolute before:h-[2px] before:w-24 before:bg-troo-black1 before:bottom-0 before:left-0">
                Tage: Insurance, Life Insurance
              </p>
            </div>
            <img
              className="w-full max-h-[500px]"
              src={picture || DetailsImg}
              alt="DetailsImg"
            />
            <p>
              Slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>

            <h5 className="text-troo-white p-6 bg-troo-primary">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </h5>
            <h3>Do I Need Insurance?</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>
            <h3>Liablitty Coverage</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>
            <h3>Medical Expenses Coverage</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>
            <h3>Peace of Mind</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly.
            </p>
            <div className="flex justify-between items-center w-full">
              <div>
                <img
                  className="w-full"
                  src={detailsSideImg}
                  alt="details img"
                />
              </div>
              <div className="flex flex-col gap-3 mr-5">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Using powerful lorem dollr
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Using powerful lorem dollr
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>

            <div className="bg-troo-whitefade flex items-center gap-3 p-3 w-full">
              <h5>Share This Article:</h5>
              <FaFacebookF />
              <FaInstagram />
              <FaLinkedinIn />
              <FaTwitter />
            </div>
            <h3 className="mt-7">Comments (0)</h3>
            <h4>Leave A Comment</h4>
            <form className="mt-6 w-full">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full">
                    <p className="mb-3">First Name</p>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="p-5 border focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Last Name</p>
                    <input
                      type="number"
                      placeholder="Last Name"
                      className="p-5 border focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Email</p>
                    <input
                      type="email"
                      placeholder="Email"
                      className="p-5 border focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Phone</p>
                    <input
                      type="text"
                      placeholder="Enter Your Phone"
                      className="p-5 border focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="mb-3">Write Comment</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-5 border focus:outline-none focus-visible:ring-1 rounded w-full"
                    rows={8}
                  />
                </div>
                <div>
                  <Link className="btn1 bg-troo-primary">Post Comment</Link>
                </div>
              </div>
            </form>
            
          </div>
          <div className="w-full md:sticky top-40 lg:w-[32%]  ">
            <div className="flex flex-col items-start gap-6 bg-troo-whitefade shadow-sm p-6 rounded mb-10">
              <h4 className="pb-3 border-b-2 w-full  border-troo-black1">
                Blog Categories
              </h4>
              <div className="flex flex-col gap-5 w-full">
                {ServiceData.slice(0, 7).map((e, i) => (
                  <p
                    key={i}
                    className="hover:text-troo-white rounded p-3 bg-troo-white flex justify-between items-center hover:bg-troo-primary w-full transition-all ease-in-out duration-500"
                  >
                    {e.name}{" "}
                    <BsArrowRight className="rounded-full border border-troo-secondary p-1 w-6 h-6" />
                  </p>
                ))}
              </div>
            </div>
            <div className="bg-troo-whitefade rounded shadow-sm p-6 w-full mb-10">
              <h4 className="pb-3 mb-5 border-b-2 w-full  border-troo-black1">
                Popular Tags
              </h4>
              <div className=" flex flex-wrap gap-2 w-full gap-y-5">
                <p className="p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white rounded transition-all ease-in-out duration-500 ">
                  Insutance
                </p>
                <p className="p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white rounded transition-all ease-in-out duration-500 ">
                  Auto Insurance
                </p>
                <p className="p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white rounded transition-all ease-in-out duration-500 ">
                  Life Insurance
                </p>
                <p className="p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white rounded transition-all ease-in-out duration-500 ">
                  Property Insurance
                </p>
                <p className="p-2 bg-troo-white hover:bg-troo-primary hover:text-troo-white rounded transition-all ease-in-out duration-500 ">
                  Home Insurance
                </p>
              </div>
            </div>
            <div className="bg-troo-whitefade rounded shadow-sm p-6 w-full mb-10">
              <h4 className="pb-3 mb-5 border-b-2 w-full  border-troo-black1">
                Related Blogs
              </h4>
              <div className="flex flex-col gap-5 w-full ">
                {BlogDetailsData.map((e, i) => (
                  <div className="flex items-center gap-5 group" key={i}>
                    <div className="w-[200px] relative">
                      <img className="w-full" src={e.img} alt="blog img" />
                      <div className="rounded-full absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center bg-troo-black6 scale-0 group-hover:scale-100 transition-all ease-in-out duration-500">
                        <GoArrowUpRight className="bg-troo-primary p-2 h-8 w-8 flex justify-center items-center rounded-full" />
                      </div>
                    </div>
                    <div>
                      <p className="font-bold">{e.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
