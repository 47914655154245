import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Contacts from '../Components/Contacts'
import Brands from '../Components/Brands'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Insurance | Contact Page")
  return (
    <Layout>
    <BannerGlobal />
    <Contacts/>
    <Brands/>
  </Layout>
  )
}

export default ContactPage