import React from "react";
import { TeamData } from "../Data/TeamData";
import { Link, createSearchParams } from "react-router-dom";

const Team = ({ showHeading }) => {
  return (
    <div
      className={` py-12 lg:py-24 ${
        showHeading ? "bg-team-bg bg-no-repeat" : ""
      }`}
    >
      <div className="main_container1">
        {showHeading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-20 gap-8 mb-16">
            <div className="flex flex-col gap-4 py-8">
              <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
                Meet Our Team Member
              </p>
              <h2 className="max-w-[400px] ">
                Let’s Meet Our Professional Team
              </h2>
              <p className="max-w-[350px]">
                There are many variations of also passages of and it is Lorem we
                are Ipsum available, but the majority is are many are many
                variations.
              </p>
              <div>
                <Link to="/our-team" className="btn1 border-troo-secondary hover:bg-troo-secondary hover:text-troo-white">
                  Meet All Member
                </Link>
              </div>
            </div>
            {TeamData.slice(0, 2).map((e, i) => (
              <div>
                <Link
                to={`/our-team/our-team-details?${createSearchParams({id :e.id})}`}
                  key={i}
                  className="relative group before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto"
                >
                  <div className="bg-troo-secondary rounded">
                    <img
                      className="w-full rounded group-hover:opacity-40 transition-all ease-in-out"
                      src={e.img}
                      alt={e.img}
                    />
                  </div>
                  <div className="absolute w-[80%] mx-auto p-5  bottom-0 rounded group-hover:opacity-0 bg-troo-secondary left-0 right-0 text-troo-white flex flex-col items-center opacity-100 transform scale-100 transform-origin-top center group-hover:transform group-hover:scale-y-0 group-hover:transform-origin-bottom group-hover:center">
                    <h3>{e.name}</h3>
                    <p>{e.sub}</p>
                  </div>
                  <div className="absolute w-[90%]  mx-auto rounded left-0 right-0 bottom-[-40px] opacity-0 transform scale-y-0 transition-transform duration-500 ease-in-out group-hover:opacity-100 group-hover:transform group-hover:scale-100 group-hover:transform-origin-top group-hover:center">
                    <div className="bg-troo-primary flex flex-col items-center text-troo-white p-5">
                      <h3>{e.name}</h3>
                      <p>{e.sub}</p>
                    </div>
                    <div className="flex justify-center gap-4 bg-troo-secondary p-2">
                      {e.social.map((ele, ind) => (
                        <div
                          key={ind}
                          className="bg-troo-primary rounded-full p-1"
                        >
                          {ele.icon}
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            {TeamData.slice(2, 5).map((e, i) => (
              <Link
              to={`/our-team/our-team-details?${createSearchParams({id :e.id})}`}
                key={i}
                className="relative group before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto"
              >
                <div className="bg-troo-secondary rounded">
                  <img
                    className="w-full rounded group-hover:opacity-40 transition-all ease-in-out"
                    src={e.img}
                    alt={e.img}
                  />
                </div>
                <div className="absolute w-[80%] mx-auto p-5  bottom-0 rounded group-hover:opacity-0 bg-troo-secondary left-0 right-0 text-troo-white flex flex-col items-center opacity-100 transform scale-100 transform-origin-top center group-hover:transform group-hover:scale-y-0 group-hover:transform-origin-bottom group-hover:center">
                  <h3>{e.name}</h3>
                  <p>{e.sub}</p>
                </div>
                <div className="absolute w-[90%]  mx-auto rounded left-0 right-0 bottom-[-40px] opacity-0 transform scale-y-0 transition-transform duration-500 ease-in-out group-hover:opacity-100 group-hover:transform group-hover:scale-100 group-hover:transform-origin-top group-hover:center">
                  <div className="bg-troo-primary flex flex-col items-center text-troo-white p-5">
                    <h3>{e.name}</h3>
                    <p>{e.sub}</p>
                  </div>
                  <div className="flex justify-center gap-4 bg-troo-secondary p-2">
                    {e.social.map((ele, ind) => (
                      <div
                        key={ind}
                        className="bg-troo-primary rounded-full p-1"
                      >
                        {ele.icon}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-20 gap-8">
            {TeamData.map((e, i) => (
              <Link
              to={`/our-team/our-team-details?${createSearchParams({id :e.id})}`}
                key={i}
                className="relative group before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto"
              >
                <div className="bg-troo-secondary rounded">
                  <img
                    className="w-full rounded group-hover:opacity-40 transition-all ease-in-out"
                    src={e.img}
                    alt={e.img}
                  />
                </div>
                <div className="absolute w-[80%] mx-auto p-5  bottom-0 rounded group-hover:opacity-0 bg-troo-secondary left-0 right-0 text-troo-white flex flex-col items-center opacity-100 transform scale-100 transform-origin-top center group-hover:transform group-hover:scale-y-0 group-hover:transform-origin-bottom group-hover:center">
                  <h3>{e.name}</h3>
                  <p>{e.sub}</p>
                </div>
                <div className="absolute w-[90%]  mx-auto rounded left-0 right-0 bottom-[-40px] opacity-0 transform scale-y-0 transition-transform duration-500 ease-in-out group-hover:opacity-100 group-hover:transform group-hover:scale-100 group-hover:transform-origin-top group-hover:center">
                  <div className="bg-troo-primary flex flex-col items-center text-troo-white p-5">
                    <h3>{e.name}</h3>
                    <p>{e.sub}</p>
                  </div>
                  <div className="flex justify-center gap-4 bg-troo-secondary p-2">
                    {e.social.map((ele, ind) => (
                      <div
                        key={ind}
                        className="bg-troo-primary rounded-full p-1"
                      >
                        {ele.icon}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        {showHeading && (
          <div className="text-center ">
            <div className="bg-service-insurance bg-no-repeat bg-clip-text text-transparent font-extrabold antialiased opacity-20">
              <p className="font-redHate text-5xl md:text-7xl  2xl:text-9xl">Insurance Services</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Team;
