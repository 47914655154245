import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { ServiceData } from "../Data/ServiceData";
import { CaseStudyData } from "../Data/CastStudyData";
import { TeamData } from "../Data/TeamData";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import wave from "../images/wave.png";
import { BsArrowRight } from 'react-icons/bs';

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("services-details")) {
        const sData = ServiceData.find((e) => e.id == id);
        setHeading(sData.name);
      }
      if (pathName.includes("case-study-details")) {
        const wData = CaseStudyData.find((e) => e.id == id);
        setHeading(wData.name);
      }
      if (pathName.includes("team-details")) {
        const tData = TeamData.find((e) => e.id == id);
        setHeading(tData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);
  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="py-12 lg:py-24  bg-banner-global bg-no-repeat bg-cover bg-bottom w-ful h-full relative after:content-[''] after:absolute after:bg-troo-black8 after:w-full after:h-full after:top-0 after:left-0 after:z-[-1] z-[1]">
      <div className="main_container1 py-12 lg:py-24">
        <div className="flex flex-col justify-center items-center">
          <div>
            <h2 className="text-troo-white text-center mb-8">{heading}</h2>
            <div className="text-center relative z-[1]">
            <ul className="inline-flex items-center rounded bg-troo-white flex-wrap gap-1 justify-center px-5 py-3 text-troo-secondary relative before:content-[''] before:absolute before:border before:border-troo-primary before:w-full before:h-full before:top-2 before:left-2 before:z-[-1] z-auto">
                <Link to="/">Home</Link>
                <span><BsArrowRight/></span>
                {breadcrumbs.map((breadcrumb, index) => (
                  <React.Fragment key={index}>
                    <li
                      className={`capitalize text-troo-secondary ${
                        index === breadcrumbs.length - 1 ? "text-troo-primary" : ""
                      }`}
                    >
                      <Link
                        to={breadcrumb.path}
                        className={breadcrumb.path === pathName ? "text-troo-primary" : ""}
                      >
                        {breadcrumb.title.replace(/-/g, " ")}
                      </Link>
                    </li>
                    {index !== breadcrumbs.length - 1 && <span><BsArrowRight/></span>}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[-1px]">
        <img src={wave} alt="wave" />
      </div>
    </div>
  );
};

export default BannerGlobal;
