import React from "react";
import { FileData } from "../Data/FileData";
import sIcon from "../images/sIcon.png";

const FileCard = () => {
  const cardElements = FileData.map((e, i) => (
    <div className=" flex flex-col items-center gap-3" key={i}>
      <div className="bg-troo-secondary w-32 h-32 relative flex justify-center items-center border-8 border-troo-primary outline-dashed outline-1 outline-troo-white rounded-[50%]">
        <img src={e.icon} alt={e.icon} />
        <p className="absolute bg-troo-secondary text-troo-white w-8 h-8 bottom-3 left-[-20px] rounded-[50%] flex justify-center items-center border-2 border-troo-primary outline-dashed outline-1 outline-troo-primary">
          0{e.id}
        </p>
      </div>
      <h4 className="text-center">{e.name}</h4>
      <p className="max-w-[300px] text-center">{e.para}</p>
    </div>
  ));

  const arrowElement = (
    <div className="flex items-center justify-center transform rotate-x-0 rotate-y-0 rotate-90 lg:rotate-0">
      <img className="py-16" src={sIcon} alt="sicon" />
    </div>
  );
  return (
    <div className="main_container2 bg-troo-primary py-10 rounded">
      <div className="grid grid-cols-1  lg:grid-cols-7">
        {cardElements.map((card, index) => (
          <React.Fragment key={index}>
            {card}
            {index < cardElements.length - 1 && arrowElement}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FileCard;
