import React from "react";
import Layout from "../Components/Layout";
import BannerGlobal from "../Components/BannerGlobal";
import About from "../Components/About";
import FileCard from "../Components/FileCard";
import Feedback from "../Components/Feedback";
import Team from "../Components/Team";
import Video from "../Components/Video";
import FAQs from "../Components/FAQs";
import PricingCard from "../Components/PricingCard";
import Highlights from "../Components/Highlights";
import ChooseUs from "../Components/ChooseUs";
import Brands from "../Components/Brands";
import useDocumentTitle from "../Hooks/PageTitle";

const AboutPage = () => {
  useDocumentTitle("TRoo Insurance | Abouts Page")
  return (
    <Layout>
      <BannerGlobal />
      <About />
      <div className="main_container2 mx-auto">
        <FileCard />
      </div>
      <Feedback />
      <Team />
      <Video />
      <PricingCard />
      <FAQs />
      <Highlights />
      <ChooseUs />
      <Brands/>
    </Layout>
  );
};

export default AboutPage;
