import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Service from "../../Components/Service";
import Brands from "../../Components/Brands";
import useDocumentTitle from "../../Hooks/PageTitle";

const ServicePage = () => {
  useDocumentTitle("TRoo Insurance | Service Page")
  return (
    <Layout>
      <BannerGlobal />
      <Service showHeading={false}/>
      <Brands/>
    </Layout>
  );
};

export default ServicePage;
