export const PolicyData = [
    {
        id : 1,
        name : "Get Information",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt."
    },
    {
        id : 2,
        name : "Fast & EasyProcess",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt."
    },
    {
        id : 3,
        name : "Quick ClaimHandle",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt."
    },
    {
        id : 4,
        name : "Save YourMoney",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt."
    },
]