import React, { useEffect, useState } from "react";
import { FaqData } from "../Data/FaqData";
import { Link } from "react-router-dom";
import client1 from "../images/client1.png";
import { CaseStudyData } from "../Data/CastStudyData";

const FAQs = ({ showHeading, faqfilter }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showHeading) {
      setData(FaqData.slice(0, 6));
    } else {
      setData(FaqData);
    }
  }, []);

  return (
    <div
      className={` py-12 lg:py-24 
     ${showHeading ? "bg-faq-bg bg-no-repeat w-full bg-troo-whitefade" : ""}`}
    >
      <div className="main_container1">
        {showHeading && (
          <div className="mb-12">
            <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Most Recent FAQ’s
            </p>
            <h2 className=" mb-5">Usually Asked Question From Client</h2>
            <p className="max-w-[700px]">
              There are many variations of also passages of and it is Lorem we
              are Ipsum available, but the majority is are many are many
              variations.
            </p>
          </div>
        )}
        {faqfilter && (
          <div className="flex justify-center mb-12">
            <div className="flex justify-center flex-wrap gap-6">
              <p className="px-5 py-3 rounded border border-troo-secondary ">
                All FAQ’s
              </p>
              {CaseStudyData.slice(0, 6).map((e, i) => (
                <p className="px-5 py-3 rounded border border-troo-secondary" key={i}>
                  {e.sub}
                </p>
              ))}
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 z-[1] relative mb-12">
          {data.map((e, i) => (
            <Link
              key={i}
              className={`flex flex-col gap-3  p-7 rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto ${
                showHeading ? "bg-troo-white" : "bg-troo-whitefade"
              }`}
            >
              <h3 className="group-hover:text-troo-primary transition-all ease-in-out duration-500">
                {e.name}
              </h3>
              <p>{e.para}</p>
            </Link>
          ))}
        </div>

        {showHeading && (
          <div className="flex flex-col md:flex-row justify-between gap-5 items-center">
            <div className="flex flex-col md:flex-row items-center gap-5">
              <div className=" w-24 h-24 relative flex justify-center items-center border-4 border-troo-white outline-dashed outline-1 outline-troo-primary group-hover:outline-troo-secondary rounded-[50%]">
                <img className="rounded-[50%]" src={client1} alt="cleint1" />
              </div>
              <div className="flex flex-col gap-2 text-center md:text-left">
                <h3>Still Have You Questions?</h3>
                <p>
                  Can’t Find The Answer You Are Looking For?
                  <span className="cursor-pointer font-bold">Click Here</span>
                  to Chat With Us
                </p>
              </div>
            </div>
            <div>
              <Link
                to="/faq"
                className="btn1 border-troo-secondary hover:bg-troo-secondary hover:text-troo-white"
              >
                View All FAQ’s
              </Link>
            </div>
          </div>
        )}
        {faqfilter && (
          <div className="text-center">
            <Link className="bg-troo-primary inline-flex flex-col gap-2 px-6 py-5 rounded">
              <h3>Still Have You Questions?</h3>
              <p>
                Can’t Find The Answer You Are Looking For? Click Here to Chat
                With Us
              </p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQs;
