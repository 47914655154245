import React from "react";
import CaseStudyDEtailsImg from "../images/caseStudyDetailsImg.png";
import { HiOutlineUser } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";
import { BsCheckLg, BsTag } from "react-icons/bs";
import { TbCoins } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import details1 from "../images/detailsImg1.png";
import details2 from "../images/detailsImg2.png";
import details3 from "../images/detailsImg3.png";
import details4 from "../images/detailsImg4.png";

const CaseStudyDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-8">
          <div>
            <img
              className="w-full"
              src={CaseStudyDEtailsImg}
              alt="caseStudyImgDetails"
            />
          </div>
          <h2>Best Insurance Policies for Families.</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.There are many variations of passages of Lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour, or randomised words which don't look even
            slightly believable.There are many variations of passages of Lorem
            Ipsum available, but the majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.There are many variations of passages of Lorem
            Ipsum available, but the majority have suffered alteration in some
            form, by injected humour, or randomised words which don't look even
            slightly believable.
          </p>
          <div className="flex justify-between flex-wrap  p-10 bg-troo-whitefade">
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-green2 p-3 flex justify-center items-center rounded">
                <HiOutlineUser className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-green2 p-3 flex justify-center items-center rounded">
                <CiLocationOn className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-green2 p-3 flex justify-center items-center rounded">
                <BsTag className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-green2 p-3 flex justify-center items-center rounded">
                <CiCalendarDate className="text-xl font-bold text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-green2 p-3 flex justify-center items-center rounded">
                <TbCoins className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
          </div>
          <div className="flex gap-5 justify-between">
            <h3>The Challenges</h3>
            <p className="max-w-[950px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some
              form, by injected humour, or randomised words which don't look
              even slightly believable.There are many variations of passages of
              Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which don't
              look even slightly believable.There are many variations of
              passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form, by injected humour, or randomised words
              which don't look even slightly believable.
            </p>
          </div>
          <div className="flex gap-8">
            <img className="w-full" src={details1} alt="details1" />
            <img className="w-full" src={details2} alt="details2" />
          </div>
          <div className="flex gap-5 justify-between">
            <h3>The Solutions</h3>
            <p className="max-w-[950px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some
              form, by injected humour, or randomised words which don't look
              even slightly believable.There are many variations of passages of
              Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which don't
              look even slightly believable.There are many variations of
              passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form, by injected humour, or randomised words
              which don't look even slightly believable.
            </p>
          </div>
          <div className="flex gap-8">
            <img className="w-full" src={details3} alt="details3" />
            <img className="w-full" src={details4} alt="details4" />
          </div>
          <div className="flex gap-5 justify-between">
            <h3>The Result</h3>
            <div className="max-w-[950px] flex flex-col gap-4">
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.There are many variations of passages of
                Lorem Ipsum available, but the majority have suffered alteration
                in some form, by injected humour, or randomised words which
                don't look even slightly believable.
              </p>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.There are many variations of passages of
                Lorem Ipsum available, but the majority have suffered alteration
                in some form.
              </p>
              <div className="sm:flex justify-between gap-5 w-full">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Using powerful lorem dollr 
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetails;
