import blog1 from "../images/blog1.png"
import blog2 from "../images/blog2.png"
import blog3 from "../images/blog3.png"
import blog4 from "../images/blog4.png"
import blog5 from "../images/blog5.png"
import blog6 from "../images/blog6.png"
import blog7 from "../images/blog7.png"
import blog8 from "../images/blog8.png"
import blog9 from "../images/blog9.png"

export const BlogData = [
    {
        id : 1,
        name :"3 life insurance underwriting predictions for 2023",
        img :blog1,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "Travel Insurance, Insurance"
    },
    {
        id : 2,
        name :"Should North American life insurers stop prioritizing share buybacks?",
        img :blog2,
        date :"23 March, 2023",
        pname : "Allie Grater",
        para : "House Insurance, Insurance"
    },
    {
        id : 3,
        name :"Exploring ecosystem maturity in Latin American insurance",
        img :blog3,
        date :"11 March, 2023",
        pname : "Allie Grater",
        para : "Family Insurance, Insurance"
    },
    {
        id : 4,
        name :"Can Smokers Get a Term Insurance Policy?",
        img :blog4,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "House Insurance, Insurance"
    },
    {
        id : 5,
        name :"Applying for Life Insurance with a History of Illness",
        img :blog5,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "Family Insurance, Insurance"
    },
    {
        id : 6,
        name :"Budget 2023: Government Announces New Tax on Insurance Policies",
        img :blog6,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "Travel Insurance, Insurance"
    },
    {
        id : 7,
        name :"Annual Premiums Over ₹5 Lakh on Life Insurance Policies",
        img :blog7,
        date :"25 March, 2022",
        pname : "Allie Grater",
        para : "Car Insurance, Insurance"
    },
    {
        id : 8,
        name :"How to Save before Bike Insurance Prices Increase in April",
        img :blog8,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "Health Insurance, Insurance"
    },
    {
        id : 9,
        name :"General Insurance Claim Settlement Ratio of General Insurance",
        img :blog9,
        date :"25 March, 2023",
        pname : "Allie Grater",
        para : "Family Insurance, Insurance"
    },
]