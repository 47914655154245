import React from "react";
import Error404 from "../../Components/Error404";
import Header from "../../Components/Header";
import useDocumentTitle from "../../Hooks/PageTitle";

const ErrorPage = () => {
  useDocumentTitle("TRoo Insurance | Error404 Page")
  return (
    <div>
      <Header />
      <Error404 />
    </div>
  );
};

export default ErrorPage;
