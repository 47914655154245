import team1 from "../images/team1.png";
import team2 from "../images/team2.png";
import team3 from "../images/team3.png";
import team4 from "../images/team4.png";
import team5 from "../images/team5.png";
import team6 from "../images/team6.png";
import team7 from "../images/team7.png";
import team8 from "../images/team8.png";
import team9 from "../images/team9.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

export const TeamData = [
  {
    id: 1,
    name: "Clark Sanford",
    img: team1,
    sub: "SEO and Founder",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 2,
    name: "Christa Archer",
    img: team2,
    sub: "Co-founder",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 3,
    name: "Reinaldo Schmidt",
    img: team3,
    sub: "HR Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 4,
    name: "Stewart Roberts",
    img: team4,
    sub: "IT-Executive",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 5,
    name: "Claire Thompson",
    img: team5,
    sub: "Marketing Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 6,
    name: "Claire Thompson",
    img: team6,
    sub: "Marketing Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 7,
    name: "Stewart Roberts",
    img: team7,
    sub: "IT-Executive",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 8,
    name: "Claire Thompson",
    img: team8,
    sub: "Marketing Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 9,
    name: "Claire Thompson",
    img: team9,
    sub: "Marketing Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
];
