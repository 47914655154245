import React from "react";
import { BrandData } from "../Data/BrandData";

const Brands = () => {
  return (
    <div className="pb-12 lg:pb-24">
      <div className="main_container1">
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 justify-center gap-3">
          {BrandData.map((e, i) => (
            <div className="flex justify-center" key={i}>
              <img src={e.logo} alt="brandLogos" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
