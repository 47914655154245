import React from "react";
import highlightRight from "../images/highlightImg.png";
import { TbPhoneCall } from "react-icons/tb";
import { HiOutlineMail } from 'react-icons/hi';

const Highlights = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:top-0 before:left-0 before:bg-troo-secondary before:opacity-30 w-full">
        <img
          className="w-full h-full"
          src={highlightRight}
          alt={highlightRight}
        />
      </div>
      <div className="bg-highlight-bg bg-no-repeat w-full bg-contain bg-troo-secondary flex items-center ">
        <div className="ml-0 2xl:ml-24 py-10 px-5 ">
          <div className="flex flex-col gap-4 text-troo-white z-[1] relative">
            <h2 className="max-w-[500px]">
              We're Delivering The Best Customer Experience
            </h2>
            <p className="max-w-[500px]">
              There are many variations of also passages of and it is Lorem we
              are Ipsum available, variations of also passages of and it is
              Lorem but the majority is are many are many variations.
            </p>
            <h4>Contact With Us</h4>
            <div className="flex flex-col md:flex-row gap-y-3 justify-center md:justify-between p-5 bg-troo-primary rounded relative before:content-[''] before:absolute before:w-full before:h-full before:top-2 before:rounded before:left-2 before:bg-troo-white before:z-[-1] z-auto">
              <div className="flex gap-3">
                <div className="bg-troo-black1 p-3 flex justify-center items-center rounded">
                  <TbPhoneCall className="text-xl text-troo-secondary"/>
                </div>
                <div className="text-troo-secondary">
                  <h6>Call Us on</h6>
                  <p className="font-bold">+44 123 456 7890</p>
                </div>
              </div>
              <div className="border-l border-troo-secondary">
              </div>
              <div className="flex gap-3">
                <div className="bg-troo-black1 p-3 flex justify-center items-center rounded">
                  <HiOutlineMail className="text-xl text-troo-secondary"/>
                </div>
                <div className="text-troo-secondary">
                  <h6>Email Us on</h6>
                  <p className="font-bold">info@troothemes.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
