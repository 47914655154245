import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import CaseStudyDetails from "../../Components/CaseStudyDetails";
import Brands from "../../Components/Brands";
import useDocumentTitle from "../../Hooks/PageTitle";

const CaseStudyDetailsPage = () => {
  useDocumentTitle("TRoo Insurance | CaseStudy Details Page")
  return (
    <Layout>
      <BannerGlobal />
      <CaseStudyDetails/>
      <Brands/>
    </Layout>
  );
};

export default CaseStudyDetailsPage;
