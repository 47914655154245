import React from 'react'
import ComingSoon from '../../Components/ComingSoon'
import useDocumentTitle from '../../Hooks/PageTitle'

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Insurance | Coming Soon")
  return (
      <ComingSoon/>
  )
}

export default ComingSoonPage