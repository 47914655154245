import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TeamDetails from '../../Components/TeamDetails'
import Brands from '../../Components/Brands'
import TeamHighlight from '../../Components/TeamHighlight'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Insurance | Team Details Page")
  return (
    <Layout>
      <BannerGlobal />
      <TeamDetails />
      <TeamHighlight/>
      <Brands/>
    </Layout>
  )
}

export default TeamDetailsPage