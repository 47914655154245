import img1 from "../images/caseStudy1.png"
import img2 from "../images/caseStudy2.png"
import img3 from "../images/caseStudy3.png"
import img4 from "../images/caseStudy4.png"
import img5 from "../images/caseStudy5.png"
import img6 from "../images/caseStudy6.png"
import img7 from "../images/caseStudy7.png"
import img8 from "../images/caseStudy8.png"

export const CaseStudyData =[
    {
        id : 1,
        img :img1,
        name : "Family Care Insurance",
        sub :"Life Insurance"
    },
    {
        id : 2,
        img :img2,
        name : "Build Dream Home",
        sub :"House Insuance"
    },
    {
        id : 3,
        img :img3,
        name : "New Business Startup",
        sub :"Business Insurance"
    },
    {
        id : 4,
        img :img4,
        name : "Discover The World",
        sub :"Travel Insurance"
    },
    {
        id : 5,
        img :img5,
        name : "TATA AIG Car Insurance",
        sub :"Car Insuance"
    },
    {
        id : 6,
        img :img6,
        name : "Star Health Insurance",
        sub :"Health Insurance"
    },
    {
        id : 7,
        img :img7,
        name : "Family Health Insurance",
        sub :"Family Insurance"
    },
    {
        id : 8,
        img :img8,
        name : "Road Safety Insurance",
        sub :"General Insurance"
    },
]