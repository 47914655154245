import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BlogDetails from '../../Components/BlogDetails'
import Brands from '../../Components/Brands'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Insurance | Blogs Details Page")
  return (
    <Layout>
      <BannerGlobal />
      <BlogDetails/>
      <Brands/>
    </Layout>
  )
}

export default BlogDetailsPage