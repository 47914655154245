import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import About from '../Components/About'
import Service from '../Components/Service'
import CaseStudy from '../Components/CaseStudy'
import Feedback from '../Components/Feedback'
import Team from '../Components/Team'
import Video from '../Components/Video'
import PricingCard from '../Components/PricingCard'
import FAQs from '../Components/FAQs'
import Highlights from '../Components/Highlights'
import ChooseUs from '../Components/ChooseUs'
import Blogs from '../Components/Blogs'
import Brands from '../Components/Brands'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Insurance - React Js Template")
  return (
   <Layout>
    <Banner/>
    <About/>
    <Service showHeading={true}/>
    <CaseStudy showHeading={true}/>
    <Feedback showHeading={true}/>
    <Team showHeading={true}/>
    <Video/>
    <PricingCard showHeading={true}/>
    <FAQs showHeading={true}/>
    <Highlights/>
    <ChooseUs/>
    <Blogs showHeading={true}/>
    <Brands/>
   </Layout>
  )
}

export default Home