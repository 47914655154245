import React, { useEffect, useState } from "react";
import { FeedbackData } from "../Data/FeedbackData";
import quote from "../images/quote.png";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

const Feedback = ({ showHeading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showHeading) {
      setData(FeedbackData.slice(0, 3));
    } else setData(FeedbackData);
  }, []);

  return (
    <div
      className={` py-24 ${
        showHeading ? "bg-feedback-bg bg-no-repeat" : " "
      }`}
    >
      <div className="main_container1">
        {showHeading && (
          <div className="flex flex-col items-center mb-10">
            <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Clients Awesome Feedback
            </p>
            <h2 className="max-w-[600px] mx-auto text-center">
              Clients Say About Insurance Services and Our Team
            </h2>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-12 relative mb-10">
          {data.map((e, i) => (
            <div
              key={i}
              className="p-5 bg-troo-whitefade rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto hover:shadow-sm"
            >
              <div className="flex items-center justify-between mb-5">
                <div className=" w-24 h-24 relative flex justify-center items-center border-4 border-troo-white outline-dashed outline-1 outline-troo-primary group-hover:outline-troo-secondary rounded-[50%]">
                  <img className="rounded-[50%]" src={e.img} alt={e.img} />
                </div>
                <div>
                  <img src={quote} alt="quote" />
                </div>
              </div>
              <div className="pb-6 border-b border-troo-black1 mb-5">
                <h3 className="group-hover:text-troo-primary">{e.name}</h3>
                <p>{e.desg}</p>
              </div>
              <p className="mb-5">{e.para}</p>
              <h4 className="mb-4">Quality Services</h4>
              <div className="inline-flex p-3 text-troo-primary bg-troo-green2 gap-2">
                <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </div>
            </div>
          ))}
        </div>

        {showHeading && (
          <div className="text-center">
            <Link to="/testimonial" className="btn1 border-troo-secondary hover:bg-troo-secondary hover:text-troo-white">
              View All Feedback
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
