import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import { GoArrowUpRight } from "react-icons/go";

const Service = ({ showHeading }) => {
  return (
    <div className={`py-12 lg:py-24 ${showHeading ? "bg-troo-whitefade" : ""}`}>
      <div className="main_container1">
        {showHeading && (
          <div className="flex flex-col items-center gap-5 mb-8">
            <p className=" text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Our Insurance Services
            </p>
            <h2 className="max-w-[500px] mx-auto text-center">
              We Provide Wide Range of Insurance Services
            </h2>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 z-[1] relative mb-10">
          {showHeading ? (
            <>
              {ServiceData.slice(0, 7).map((e, i) => (
                <Link
                  key={i}
                  to={`/our-services/services-details?${createSearchParams({
                    id: e.id,
                  })}`}
                  className="bg-troo-white p-6 rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto "
                >
                  <div className="flex justify-between items-center mb-6">
                    <div className="bg-troo-green2 w-16 h-16 rounded-[50%] flex justify-center items-center  transition-all relative before:absolute before:content-[''] before:top-0 before:right-0 before:bottom-0 before:left-0 before:h-full before:w-full before:bg-troo-primary before:rounded-[50%] before:scale-y-0 group-hover:before:scale-y-100 before:z-[-1] z-[1] group-hover:before:transition-all group-hover:before:duration-500 group-hover:before:ease-in-out">
                      <img src={e.icon} alt={e.icon} />
                    </div>
                    <div className="bg-troo-black1 w-8 h-8 rounded-[50%] flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all group-hover:transition-all scale-0 group-hover:scale-100 group-hover:ease-in-out group-hover:duration-500 ">
                      <GoArrowUpRight />
                    </div>
                  </div>
                  <div>
                    <h3 className="mb-3 group-hover:text-troo-primary">
                      {e.name}
                    </h3>
                    <p>{e.para}</p>
                  </div>
                </Link>
              ))}
              <div className="flex justify-center items-center">
                <Link
                  to="/our-services"
                  className="relative flex flex-col gap-3 justify-center items-center bg-troo-primary rounded-[50%] before:content-[''] before:absolute before:border before:border-troo-secondary before:rounded-[50%] before:top-[-7px] before:left-[-1px] before:w-full before:h-full group z-[1] before:z-[-1] h-72 w-72"
                >
                  <h2>05+</h2>
                  <p>More Service We Serve too</p>
                  <Link className="btn1 group-hover:bg-troo-secondary group-hover:text-troo-primary group-hover:ease-in-out group-hover:duration-500 ">
                    View All Services
                  </Link>
                </Link>
              </div>
            </>
          ) : (
            ServiceData.map((e, i) => (
              <Link
                to={`/our-services/services-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="bg-troo-whitefade p-6 rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto "
              >
                <div className="flex justify-between items-center mb-6">
                  <div className="bg-troo-green2 w-16 h-16 rounded-[50%] flex justify-center items-center  transition-all relative before:absolute before:content-[''] before:top-0 before:right-0 before:bottom-0 before:left-0 before:h-full before:w-full before:bg-troo-primary before:rounded-[50%] before:scale-y-0 group-hover:before:scale-y-100 before:z-[-1] z-[1] group-hover:before:transition-all group-hover:before:duration-500 group-hover:before:ease-in-out">
                    <img src={e.icon} alt={e.icon} />
                  </div>
                  <div className="bg-troo-black1 w-8 h-8 rounded-[50%] flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all group-hover:transition-all scale-0 group-hover:scale-100 group-hover:ease-in-out group-hover:duration-500 ">
                    <GoArrowUpRight />
                  </div>
                </div>
                <div>
                  <h3 className="mb-3 group-hover:text-troo-primary">
                    {e.name}
                  </h3>
                  <p>{e.para}</p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showHeading && (
          <div className="text-center mb-12 lg:mb-0">
            <div className="bg-service-insurance bg-no-repeat bg-clip-text text-transparent font-extrabold antialiased opacity-20">
              <p className="font-redHate text-5xl md:text-7xl  2xl:text-9xl">
                Insurance Services
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
