import React, { useEffect, useState } from "react";
import { PricingData } from "../Data/PricingData";
import { Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";

const PricingCard = ({ showHeading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showHeading) {
      setData(PricingData.slice(0, 3));
    } else {
      setData(PricingData);
    }
  }, []);
  return (
    <div
      className={
        showHeading ? "pt-24 lg:pt-72 pb-12 lg:pb-24" : "py-12 lg:py-24"
      }
    >
      <div className="main_container1">
        {showHeading && (
          <div className="flex flex-col items-center mb-10">
            <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Best Pricing Plans
            </p>
            <h2 className="max-w-[600px] mx-auto text-center">
              Unmatched Features With Transparent Pricing
            </h2>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-10 lg:gap-y-20 lg:grid-cols-3 relative z-[1] mb-10">
          {data.map((e, i) => (
            <div
              className="group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto"
              key={i}
            >
              <div className="p-5 flex flex-col items-center gap-2 bg-troo-black1 rounded group-hover:bg-troo-secondary transition-all ease-in-out duration-500">
                <div>
                  <Link className="btn1 bg-troo-primary ">{e.name}</Link>
                </div>
                <div className="flex items-center gap-2 group-hover:text-troo-white transition-all ease-in-out duration-500">
                  <div className="text-4xl font-redHate font-bold ">
                    {e.price}{" "}
                  </div>
                  <p> / per Month</p>
                </div>
                <p className="text-center group-hover:text-troo-white transition-all ease-in-out duration-500">
                  {e.sub}
                </p>
              </div>
              <div className="px-8 py-5 bg-troo-white flex rounded flex-col gap-4">
                <h4 className="text-center mb-5">Benifits</h4>
                {e.highlights.map((ele, ind) => (
                  <p className={`${ele.className} justify-between`} key={ind}>
                    {ele.heading}
                    <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  </p>
                ))}

                <Link className="btn1 border-troo-deepblack my-3 group-hover:bg-troo-primary transition-all ease-in-out duration-500">
                  Choose This Plan
                </Link>
              </div>
            </div>
          ))}
        </div>
        {showHeading && (
          <div className="text-center">
            <Link className="bg-troo-primary inline-flex flex-col gap-1 px-6 py-2 rounded-full">
              <p>Whant to See More Plan?</p>
              <h4>CLick Here</h4>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
