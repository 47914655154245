import client1 from "../images/client1.png"
import client2 from "../images/client2.png"
import client3 from "../images/client3.png"
import client4 from "../images/client4.png"
import client5 from "../images/client5.png"
import client6 from "../images/client6.png"
import client7 from "../images/client7.png"
import client8 from "../images/client8.png"
import client9 from "../images/client9.png"


export const FeedbackData = [
    {
        id:1,
        img : client1,
        name :"Sarah Jacobson",
        desg : "Sales Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:2,
        img : client2,
        name :"Emmanuel Coffey",
        desg : "Business Consultant",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:3,
        img : client3,
        name :"Vanessa Potts",
        desg : "Marketing Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:4,
        img : client4,
        name :"Phillip Hane",
        desg : "Sales Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:5,
        img : client5,
        name :"Lionel Langosh",
        desg : "Business Consultant",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:6,
        img : client6,
        name :"Natasha Mitchell",
        desg : "Marketing Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:7,
        img : client7,
        name :"Myra Wilderman",
        desg : "Sales Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:8,
        img : client8,
        name :"Brett Morissette",
        desg : "Business Consultant",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
    {
        id:9,
        img : client9,
        name :"Catherine Mayert",
        desg : "Marketing Manager",
        para : "There are many variations of also passages of and it is Lorem we are Ipsum available, but the majority is are many are many variations of also variations of also have."
    },
   
]