import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/Logo2.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { HiOutlinePhone } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";
import wave from "../images/wave2.png";

const Footer = () => {
  return (
    <div className="bg-footer-bg bg-no-repeat relative bg-bottom bg-troo-secondary w-ful h-full pt-12 lg:pt-44">
      <div className="main_container1">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-7 w-full lg:gap-12 mb-12">
          <div className="flex flex-col md:flex-row justify-between gap-10 text-troo-white">
            <div className="flex flex-col gap-5">
              <Link>
                <img src={logo} alt="logo" />
              </Link>
              <p className="md:w-[350px] w-full">
                Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
                diska. Jinesade bel när feras redorade
              </p>

              <h3>Ready to get started?</h3>
              <div>
                <Link to="/contact-us" className="btn1 bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-secondary">
                  Get a Quote
                </Link>
              </div>
            </div>
            <div>
              <h4 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4  ">
                Navigation
              </h4>
              <nav className="flex flex-col gap-4">
                <Link className="flex items-center">About us</Link>
                <Link className="flex items-center">Our Blogs</Link>
                <Link className="flex items-center">Our Portfolio</Link>
                <Link className="flex items-center">Jobs</Link>
                <Link className="flex items-center">Contact Us</Link>
              </nav>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-8 justify-around text-troo-white">
            <div>
              <h4 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4">Our Services</h4>
              <nav className="flex flex-col gap-4">
                <Link className="flex items-center">
                Family Insurance
                </Link>
                <Link className="flex items-center">
                Life Insurance
                </Link>
                <Link className="flex items-center">
                Health Insurance
                </Link>
                <Link className="flex items-center">
                Auto Insurance
                </Link>
                <Link className="flex items-center">
                View All Services
                </Link>
              </nav>
            </div>
            <div className="border-8 border-troo-primary rounded p-5">
              <h3 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4">Contact Us</h3>
              <nav className="flex flex-col gap-4">
                <div className="flex gap-3 ">
                  <div>
                    <HiOutlinePhone className="bg-troo-whitefade1 p-2 rounded-[50%] w-10 h-10 text-troo-primary" />
                  </div>
                  <div>
                    <p>Call Us on</p>
                    <p>+44 123 456 7890</p>
                  </div>
                </div>
                <div className="flex gap-3 ">
                  <div>
                    <FiMail className="bg-troo-whitefade1 p-2 rounded-[50%] w-10 h-10 text-troo-primary" />
                  </div>
                  <div>
                    <p>Email Us on</p>
                    <p>example@email.com</p>
                  </div>
                </div>
                <div className="flex gap-3 ">
                  <div>
                    <CiLocationOn className="bg-troo-whitefade1 p-2 rounded-[50%] w-10 h-10 text-troo-primary" />
                  </div>
                  <div>
                    <p>Address</p>
                    <p className="max-w-[200px]">
                      119 Tanglewood Lane Gulfport, MS 39503
                    </p>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="lg:flex justify-between gap-5 text-troo-white py-3 border-t border-troo-green2">
          <div className="bg-troo-whitefade1  py-1 rounded">
            <p>Copyright © 2023. troothemes. All rights reserved.</p>
          </div>
          <div className="flex items-center gap-1 text-troo-white">
            <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
              <FaFacebookF />
            </Link>
            <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
              <FaInstagram />
            </Link>
            <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
              <FaTwitter />
            </Link>
            <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
              <FaLinkedinIn />
            </Link>
          </div>
          <div className="flex gap-5 bg-troo-whitefade1 px-7 py-1 rounded">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
      <div className="absolute top-[-1px] ">
        <img src={wave} alt="wave" />
      </div>
    </div>
  );
};

export default Footer;
