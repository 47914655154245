import React from 'react'
import { Link } from 'react-router-dom'

const TeamHighlight = () => {
  return (
    <div className='bg-troo-whitefade py-12 lg:py-20 mb-10 lg:mb-20'>
        <div className='main_container1'>
        <div className="flex flex-col items-center gap-10">
          <p className=" text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
            Our Insurance Services
          </p>
          <h2 className="max-w-[500px] mx-auto text-center">
            We Provide Wide Range of Insurance Services
          </h2>
          <div><Link className='btn1 bg-troo-primary hover:bg-troo-secondary hover:text-troo-primary '>Build Your Dream Job</Link></div>
        </div>
        </div>
    </div>
  )
}

export default TeamHighlight