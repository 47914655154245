import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'

import Feedback from '../../Components/Feedback'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Insurance | Testimonials Page")
  return (
    <Layout>
      <BannerGlobal />
    
      <Feedback/>
    </Layout>
  )
}

export default TestimonialPage