import React, { useEffect, useState } from "react";
import FileCard from "./FileCard";
import { Link, createSearchParams } from "react-router-dom";
import { CaseStudyData } from "../Data/CastStudyData";
import { GoArrowUpRight } from "react-icons/go";

const CaseStudy = ({ showHeading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showHeading) {
      setData(CaseStudyData.slice(0, 4));
    } else setData(CaseStudyData);
  }, []);

  return (
    <div
      className={`${
        showHeading
          ? "bg-case-study bg-no-repeat w-full bg-troo-secondary"
          : "py-12 lg:py-24"
      }`}
    >
      {showHeading && (
        <div className="relative top-[-50px]">
          <FileCard />
        </div>
      )}
      <div
        className={`${
          showHeading ? "main_container2 mx-auto" : "main_container1"
        }`}
      >
        {showHeading && (
          <div className="flex flex-col lg:flex-row justify-between gap-8 lg:items-end mb-10 pb-10">
            <div>
              <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
                Our Recent Case Study
              </p>
              <h2 className="text-troo-white max-w-[600px]">
                Recent Completed Award Winning Case Study
              </h2>
            </div>
            <div>
              <Link
                className="rounded-[50%] bg-troo-primary w-32 h-32 flex justify-center items-center border-8 border-troo-secondary  outline-dashed outline-1 text-center outline-troo-white"
                to="/case-study"
              >
                View All Projects
              </Link>
            </div>
          </div>
        )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-20 gap-7 z-[1] relative">
            {data.map((e, i) => (
              <Link
                to={`/case-study/case-study-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="bg-troo-white rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto "
              >
                <div className="studyImg bg-troo-secondary ">
                  <img
                    className="w-full rounded group-hover:opacity-40"
                    src={e.img}
                    alt={e.img}
                  />
                </div>
                <div className="w-[90%] group-hover:shadow mx-auto p-5 absolute bottom-[-50px] rounded bg-troo-white left-0 right-0">
                  <p>{e.sub}</p>
                  <h4 className="group-hover:text-troo-primary">{e.name}</h4>
                </div>
                <div className="absolute left-5 top-5 w-8 h-8 bg-troo-primary text-troo-secondary flex justify-center items-center rounded-full opacity-0 group-hover:opacity-100 group-hover:shadow">
                  <GoArrowUpRight />
                </div>
              </Link>
            ))}
          </div>
      </div>
    </div>
  );
};

export default CaseStudy;
