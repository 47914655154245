import serviceicon1 from "../images/service1.svg";
import serviceicon2 from "../images/service2.svg";
import serviceicon3 from "../images/service3.svg";
import serviceicon4 from "../images/service4.svg";
import serviceicon5 from "../images/service5.svg";
import serviceicon6 from "../images/service6.svg";
import serviceicon7 from "../images/service7.svg";
import serviceicon8 from "../images/service8.svg";
import serviceicon9 from "../images/service9.svg";
import serviceicon10 from "../images/service10.svg";
import serviceicon11 from "../images/service11.svg";

export const ServiceData = [
  {
    id: 1,
    name: "Home Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon1
  },
  {
    id: 2,
    name: "Health Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon2
  },
  {
    id: 3,
    name: "Travel Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon3
  },
  {
    id: 4,
    name: "Business Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon4
  },
  {
    id: 5,
    name: "Auto Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon5
  },
  {
    id: 6,
    name: "Family Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon6
  },
  {
    id: 7,
    name: "General Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon7
  },
  {
    id: 8,
    name: "Fire Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon8
  },
  {
    id: 9,
    name: "Marriage insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon9
  },
  {
    id: 10,
    name: "Business Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon10
  },
  {
    id: 11,
    name: "Medical Insurance",
    para: "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is have suffered alteration.",
    icon :serviceicon7
  },
];
