import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Brands from "../../Components/Brands";
import Blogs from "../../Components/Blogs";
import useDocumentTitle from "../../Hooks/PageTitle";

const BlogsPage = () => {
  useDocumentTitle("TRoo Insurance | Blogs Page")
  return (
    <Layout>
      <BannerGlobal />
      <Blogs />
      <Brands />
    </Layout>
  );
};

export default BlogsPage;
