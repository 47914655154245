import Bdetails1 from "../images/BdetailsImg1.png"
import Bdetails2 from "../images/BdetailsImg2.png"
import Bdetails3 from "../images/BdetailsImg3.png"
import Bdetails4 from "../images/BdetailsImg4.png"
import Bdetails5 from "../images/BdetailsImg5.png"


export const BlogDetailsData = [
    {
        id : 1,
        img : Bdetails1,
        name :"Should North American life insurers stop prioritizing share buybacks?"
    },
    {
        id : 2,
        img : Bdetails2,
        name :"Should North American life insurers stop prioritizing share buybacks?"
    },
    {
        id : 3,
        img : Bdetails3,
        name :"Should North American life insurers stop prioritizing share buybacks?"
    },
    {
        id : 4,
        img : Bdetails4,
        name :"Should North American life insurers stop prioritizing share buybacks?"
    },
    {
        id : 5,
        img : Bdetails5,
        name :"Should North American life insurers stop prioritizing share buybacks?"
    },
]