import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePage from "./Routes/Services/ServicePage";
import ServiceDetailsPage from "./Routes/Services/ServiceDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import CaseStudyPage from "./Routes/Pages/CaseStudyPage";
import CaseStudyDetailsPage from "./Routes/Pages/CaseStudyDetailsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import PricingPlanPage from "./Routes/Pages/PricingPlanPage";
import FAQsPage from "./Routes/Pages/FAQsPage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogsPage from "./Routes/Blogs/BlogsPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/our-services" element={<ServicePage />} />
          <Route path="/our-services/services-details" element={<ServiceDetailsPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/our-team/our-team-details" element={<TeamDetailsPage />} />
          <Route path="/case-study" element={<CaseStudyPage />} />
          <Route path="/case-study/case-study-details" element={<CaseStudyDetailsPage />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/pricing-plan" element={<PricingPlanPage />} />
          <Route path="/faq" element={<FAQsPage />} />
          <Route path="/error-404" element={<ErrorPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/our-blogs" element={<BlogsPage />} />
          <Route path="/our-blogs/blog-details" element={<BlogDetailsPage />} />
          <Route path="/contact-us" element={<ContactPage />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
