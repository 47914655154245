import React from "react";
import { Link } from "react-router-dom";
import wave from "../images/wave.png";
import { FiUser, FiMail } from "react-icons/fi";
import { BsUmbrella } from "react-icons/bs";

const Banner = () => {
  return (
    <div className="bg-hero-img bg-no-repeat bg-cover w-ful pb-24 h-full relative before:content-[''] before:absolute before:bg-banner-bg before:bg-no-repeat  before:w-full before:h-full after:content-[''] after:absolute after:bg-troo-black8 after:w-full after:h-full after:top-0 after:left-0 after:z-[-1] before:z-[-1] z-[1]">
      <div className="main_container1 py-20 lg:py-40">
        <div className="flex flex-col lg:flex-row   gap-10 text-troo-white ">
          <div className="">
            <h5 className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Reliable Insurance Agency
            </h5>
            <h1 className="max-w-[850px] m-auto mb-5">
              We insure your life Because you never know future
            </h1>
            <p className="mb-10 max-w-[800px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              eiusmod tempor incididunt ut labore et dolore magna aliqua quis
              nostrud ullamco nisi consectetur adipiscing elit tempor
              incididunt.
            </p>
            <div className="flex-col inline-flex sm:flex-row  gap-5">
              <Link
                to="/contact-us"
                className="btn1 bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-secondary"
              >
                Let’s Get Started
              </Link>
              <Link
              to="/our-services"
                className="btn1 text-troo-white hover:bg-troo-primary hover:text-troo-secondary"
              >
                View Our Services
              </Link>
            </div>
          </div>
          <div className="">
            <div className="bg-troo-white rounded text-troo-secondary p-8 flex flex-col items-center gap-5">
              <h3>Get Consultations</h3>
              <p>There are many variations of passages.</p>
              <div className="relative w-full ">
                <FiUser className="absolute top-1/2 left-2 transform -translate-y-1/2" />
                <input
                  type="text"
                  placeholder="Full Name"
                  className="p-2 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary" 
                />
              </div>
              <div className="relative w-full">
                <FiMail className="absolute top-1/2 left-2 transform -translate-y-1/2" />
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="p-2 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                />
              </div>
              <div className="relative w-full">
                <BsUmbrella className="absolute top-1/2 left-2 transform -translate-y-1/2" />
                <select className="p-2 pl-10 bg-transparent border border-troo-hrtag rounded w-full">
                  <option disabled selected>
                    Insurance Type
                  </option>
                  <option>Home Insurance</option>
                  <option>Health Insurance</option>
                  <option>Travel Insurance</option>
                </select>
              </div>
              <Link className="btn1 mt-5 w-full text-center bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-secondary">Get A Quote</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[-1px]">
        <img src={wave} alt="wave" />
      </div>
    </div>
  );
};

export default Banner;
