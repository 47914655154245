import brand1 from "../images/brand1.svg";
import brand2 from "../images/brand2.svg";
import brand3 from "../images/brand3.svg";
import brand4 from "../images/brand4.svg";
import brand5 from "../images/brand5.svg";
import brand6 from "../images/brand6.svg";

export const BrandData =[
    {
        id : 1,
        logo : brand1
    },
    {
        id : 2,
        logo : brand2
    },
    {
        id : 3,
        logo : brand3
    },
    {
        id : 4,
        logo : brand4
    },

    {
        id : 5,
        logo : brand5
    },
    {
        id : 6,
        logo : brand6
    },

]