export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "About us - TRoo Themes",
  },
  {
    title: "our services",
    path: "#",
    subNav: [
      {
        subTitle: "our services",
        heading: "Our Insurance Services",
        path: "/our-services",
      },
      {
        subTitle: "services details",
        heading: "Home Insurance",
        path: "/our-services/services-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our team",
        heading: "Meet Our Awesome Team",
        path: "/our-team",
      },
      {
        subTitle: "our team details",
        heading: "Meet Our Awesome Team",
        path: "/our-team/our-team-details",
      },
      {
        subTitle: "case study",
        heading: "Award Winning Case Study",
        path: "/case-study",
      },
      {
        subTitle: "case study details",
        heading: "Family Care Insurance",
        path: "/case-study/case-study-details",
      },
      {
        subTitle: "testimonial",
        heading: "Clients Say About Our Services",
        path: "/testimonial",
      },
      {
        subTitle: "pricing plan",
        heading: "Transparent Pricing Plane",
        path: "/pricing-plan",
      },
   
      {
        subTitle: "faq",
        heading: "Most Common Questions ",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        heading: "Latest & Popular Blogs",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        heading: "Blog Detail Page",
        path: "/our-blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Get in Touch With Us",
  },

];
