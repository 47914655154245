
export const PricingData = [
    {
        id :1,
        name : "Basic Plan",
        price : "$19.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "30 days trial Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :2,
        name : "Premium Plan",
        price : "$59.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "Professional Design",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :3,
        name : "Family Plan",
        price : "$89.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "30 days trial Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4"
            },
        ]
    },
    {
        id :4,
        name : "Corporate Plan",
        price : "$39.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "30 days trial Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :5,
        name : "Super Plan",
        price : "$59.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "Professional Design",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :6,
        name : "Ultimate Plan",
        price : "$69.99",
        sub : "Lorem ipsum dolor sit amet is just dummy cuntent consectetur adipiscing.",
        highlights:[
            {
                heading : "30 days trial Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Synced to cloud database",
                className : "flex items-center gap-4"
            },
            {
                heading : "Social media integration",
                className : "flex items-center gap-4"
            },
            {
                heading : " Awesome Features",
                className : "flex items-center gap-4"
            },
            {
                heading : "Responsive Pricing Table",
                className : "flex items-center gap-4"
            },
            {
                heading : "Professional Design",
                className : "flex items-center gap-4"
            },
        ]
    },
]