import React from "react";
import { Link } from "react-router-dom";
import img404 from "../images/img404.png";

const Error404 = () => {
  return (
    <div className="bg-error-bg bg-no-repeat w-full py-12 lg:py-44 ">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div>
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Page Not Found</h2>
          <h3 className="max-w-[800px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h3>
          <div className="flex-col sm:flex-row flex justify-center gap-5">
            <Link
              to="/"
              className="btn1 text-center bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-secondary"
            >
             Return Home
            </Link>
            <Link className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary hover:text-troo-secondary">Report Problem</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
