import React from "react";
import about1 from "../images/aboutImg.png";
import about2 from "../images/aboutImg2.png";
import { TbDiscountCheck } from "react-icons/tb";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="bg-troo-white py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 ">
          <div className="relative">
            <div>
              <img className="w-full lg:w-auto" src={about1} alt={about1} />
            </div>
            <div className="md:absolute right-0 bottom-0 mb-[-10px] z-10">
              <img className="w-full sm:w-auto" src={about2} alt={about2} />
            </div>
            <div className="relative rounded p-6 mt-2 bg-troo-primary flex 
            flex-row gap-4 items-center overflow-hidden z-20 w-full md:w-[350px]">
              <div className="text-4xl font-bold font-redHate">25+</div>
              <div>
                <h4>Years Of Experience in Insurance Services</h4>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-7">
            <p className=" text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
              Let’s Know About TRoo Insurance
            </p>
            <h2>We Provide Effective Insurance Services and Solutaions</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing.
            </p>
            <div className="sm:flex justify-between gap-5 w-full">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  We invest for long-term results
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  We manage risk in-house, in real time
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  We maintain consistently high ratings
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  Growing your business
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  25 Years of experience
                </p>
                <p className="flex items-center gap-2">
                  <TbDiscountCheck className="text-troo-secondary text-[20px]" />
                  All kind of Insurance solutions
                </p>
              </div>
            </div>
            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Here's what to know about how to start a consulting business</h5>
            <div>
                <Link to="/about-us" className="btn1 bg-troo-primary text-troo-secondary hover:bg-troo-secondary hover:text-troo-primary">
                Learn More About Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
