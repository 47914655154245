import filecheck from "../images/filecheck.svg"

export const FileData= [
    {
        id : 1,
        icon : filecheck,
        name : "Submit Your Requirement",
        para : "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is."
    },
    {
        id : 2,
        icon : filecheck,
        name : "Arrange Meeting With Us",
        para : "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is."
    },
    {
        id :3,
        icon : filecheck,
        name : "Get Solution From Us",
        para : "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is."
    },
    {
        id : 4,
        icon : filecheck,
        name : "Get Your Insurance",
        para : "There are many variations of also passages of and it is Lorem Ipsum available, but the majority is."
    },
]