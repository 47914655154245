import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";

import Brands from "../../Components/Brands";
import Team from "../../Components/Team";
import useDocumentTitle from "../../Hooks/PageTitle";

const TeamPage = () => {
  useDocumentTitle("TRoo Insurance | Teams Page")
  return (
    <Layout>
      <BannerGlobal />
      <Team />
      <Brands/>
    </Layout>
  );
};

export default TeamPage;
