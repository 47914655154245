import React from "react";
import chooseImg from "../images/chooseImg.png";
import { ChooseData } from "../Data/ChooseData";

const ChooseUs = () => {
  return (
    <div className="bg-chooseUs-bg bg-no-repeat bg-bottom w-full py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7">
          <div>
            <div className="mb-16">
              <p className="mb-5 text-troo-secondary relative w-fit py-2 px-8 flex justify-center items-center  before:content-[''] before:left-0 before:top-0 before:z-[-1] z-[1] before:absolute before:bg-troo-primary before:w-full before:h-full before:transform before:skew-x-[-20deg]">
                Why Choose Us
              </p>
              <h2 className=" max-w-[500px] mb-5">
                Why Do People Choose TRoo Insurance Services
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 z-[1] relative">
              {ChooseData.map((e, i) => (
                <div key={i} className="group flex flex-col gap-3 p-7 bg-troo-whitefade rounded relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto">
                  <div className="">
                    <img
                      className="bg-troo-green2 p-3 rounded-full group-hover:bg-troo-primary transition-all ease-in-out duration-500"
                      src={e.img}
                      alt={e.img}
                    />
                  </div>
                  <h3>{e.name}</h3>
                  <p>{e.para}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full">
            <div className="ml-10 pl-10  relative">
              <img src={chooseImg} alt="chooseImg" />
              <div className="absolute bottom-10 left-0 ">
                <div className="bg-troo-secondary flex items-center w-64 p-5 rounded relative before:content-[''] before:absolute before:left-3 before:right-3 before:top-3 before:bottom-3 before:border-dashed before:border before:border-troo-primary before:rounded">
                  <div className="twentyFive">25+</div>
                  <h4 className="text-troo-white">Year of Experience</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
