import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Brands from '../../Components/Brands'
import FAQs from '../../Components/FAQs'
import useDocumentTitle from '../../Hooks/PageTitle'

const FAQsPage = () => {
  useDocumentTitle("TRoo Insurance | FAQs Page")
  return (
    <Layout>
    <BannerGlobal />
    <FAQs faqfilter={true}/>
    <Brands/>
  </Layout>
  )
}

export default FAQsPage