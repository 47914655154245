import React from "react";
import sdetails1 from "../images/serviceDetailsImg1.png";
import sdetails2 from "../images/serviceDetailsImg2.png";
import { GoArrowRight } from "react-icons/go";
import { PolicyData } from "../Data/PolicyData";
import { ServiceData } from "../Data/ServiceData";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";

const ServiceDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1 flex flex-col gap-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 ">
          <div className="flex flex-col gap-6">
            <h3>What We Offer</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum.
            </p>
            <div className="sm:flex  items-center lg:gap-24 md:gap:10 gap-5 w-full">
              <div className="flex flex-col gap-5">
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Using powerful lorem dollr
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Using powerful lorem dollr
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
              </div>
            </div>
          </div>
          <div className="ml-10 ">
            <img className="w-full" src={sdetails1} alt="serviceDetailsImg1" />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8">
          <div className="mr-10 ">
            <img className="w-full" src={sdetails2} alt="serviceDetailsImg2" />
          </div>
          <div className="flex flex-col gap-6">
            <h3>What We Offer</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum.
            </p>
            <div className="sm:flex  items-center gap-5 lg:gap-24 md:gap:10 w-full">
              <div className="flex flex-col gap-5">
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Using powerful lorem dollr
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Using powerful lorem dollr
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <GoArrowRight className="p-1 text-lg flex justify-center items-center w-5 h-5 rounded-full border border-troo-secondary" />
                  Every lorem ipsum dollr.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="mb-10">Insurance policies</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
            {PolicyData.map((e, i) => (
              <div
                key={i}
                className="flex flex-col gap-3 items-center bg-troo-white p-6 rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto shadow"
              >
                <div>
                  <div>0{e.id}</div>
                </div>
                <h4>{e.name}</h4>
                <p className="text-center">{e.para}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <h3 className="mb-3">Main features</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything.
          </p>
          <div className=" mx-auto">
            <h4 className="bg-troo-bluefade border-l-4 border-troo-primary bg-troo-whitefade max-w-[1100px] p-4">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </h4>
          </div>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem IpsumIpsum
            available, but the majority have suffered alteration in some form,
            by injected humour, or randomised words which don't look even
            slightly believable. If you are going to use a passage.
          </p>
        </div>
        <div>
          <h2 className="text-center mb-10">Related Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 z-[1] relative">
            {ServiceData.slice(0, 4).map((e, i) => (
              <Link
                key={i}
                className="bg-troo-white p-6 rounded group relative before:content-[''] before:absolute before:w-full before:h-full before:border before:border-troo-primary before:rounded before:transition-all before:duration-500 before:ease-in-out before:right-0 before:opacity-0 before:top-0 before:z-[-1] hover:before:top-3 hover:before:right-[-10px] hover:before:opacity-[1] z-auto "
              >
                <div className="flex justify-between items-center mb-6">
                  <div className="bg-troo-green2 w-16 h-16 rounded-[50%] flex justify-center items-center  transition-all relative before:absolute before:content-[''] before:top-0 before:right-0 before:bottom-0 before:left-0 before:h-full before:w-full before:bg-troo-primary before:rounded-[50%] before:scale-y-0 group-hover:before:scale-y-100 before:z-[-1] z-[1] group-hover:before:transition-all group-hover:before:duration-500 group-hover:before:ease-in-out">
                    <img src={e.icon} alt={e.icon} />
                  </div>
                  <div className="bg-troo-black1 w-8 h-8 rounded-[50%] flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all group-hover:transition-all scale-0 group-hover:scale-100 group-hover:ease-in-out group-hover:duration-500 ">
                    <GoArrowUpRight />
                  </div>
                </div>
                <div>
                  <h3 className="mb-3 group-hover:text-troo-primary">
                    {e.name}
                  </h3>
                  <p>{e.para}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
